import dotProp from 'dot-prop-immutable';

import {
    REDUCE_POLICY,
    REDUCE_POLICY_SUMMARY,
    REDUCE_POLICY_ELEMENT,
    REDUCE_SUBMISSION_ELEMENT,
    REDUCE_PAYMENT_ELEMENT,
    REDUCE_INSURED_ELEMENT,
    REDUCE_PARAMETER,
    REDUCE_ENTITY,
    REDUCE_QUOTING
} from '../../constants/actions/policy';

const initialState = {
    entity: null,
    parameters: [],
    policy: null,
    policySummary: null
};

function policyReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_ENTITY:
            return dotProp.set(state, 'entity', action.payload);
        case REDUCE_POLICY_ELEMENT:
            if (state.policy) {
                return dotProp.set(state, `policy.${action.payload.code}`, action.payload.value);
            } else {
                let policy = dotProp.set({}, `${action.payload.code}`, action.payload.value);
                return dotProp.set(state, 'policy', policy);
            }
        case REDUCE_SUBMISSION_ELEMENT:
            if (state.policy) {
                return dotProp.set(state, `policy.submission.${action.payload.code}`, action.payload.value);
            } else {
                let policy = dotProp.set({}, `${action.payload.code}`, action.payload.value);
                return dotProp.set(state, 'policy', policy);
            }
        case REDUCE_PAYMENT_ELEMENT:
            if (state.policy) {
                return dotProp.set(state, `policy.payment.${action.payload.code}`, action.payload.value);
            } else {
                let policy = dotProp.set({}, `${action.payload.code}`, action.payload.value);
                return dotProp.set(state, 'policy', policy);
            }
        case REDUCE_INSURED_ELEMENT:
            if (state.policy) {
                return dotProp.set(state, `policy.participants.insured.${action.payload.code}`, action.payload.value);
            } else {
                let policy = dotProp.set({}, `${action.payload.code}`, action.payload.value);
                return dotProp.set(state, 'policy', policy);
            }
        case REDUCE_POLICY_SUMMARY:
            return dotProp.set(state, 'policySummary', action.payload);
        case REDUCE_POLICY:
            return dotProp.set(state, 'policy', action.payload);
        case REDUCE_PARAMETER:
            if (!action.payload) return state;

            let parameterIndex = 0;
            if (state.parameters.some(x => x.code === action.payload.code)) {
                parameterIndex = state.parameters.findIndex(x => x.code === action.payload.code);
            } else {
                parameterIndex = state.parameters.length;
            }
            return dotProp.set(state, `parameters.${parameterIndex}`, { code: action.payload.code, value: action.payload.value });
        case REDUCE_QUOTING:
            return dotProp.set(state, 'quoting', action.payload);
        default:
            return state;
    }
}

export default policyReducer;
