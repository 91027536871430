import { all, takeLatest, fork } from 'redux-saga/effects';

import {
    GET_STOREFRONT_CONFIGURATION
} from '../../constants/actions/storefront';

import getStorefrontConfigurationSaga from './getStorefrontConfigurationSaga';

function* watchConfiguration() {
    yield takeLatest(GET_STOREFRONT_CONFIGURATION, getStorefrontConfigurationSaga);
}

function* root() {
    return yield all([
        fork(watchConfiguration)
    ]);
}

export default root;