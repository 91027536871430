import React from 'react';
import {
    BrowserRouter,
    Route,
    Switch
} from 'react-router-dom';

import { Container } from 'metrix-common';

const Home = React.lazy(() => import('../../containers/Home'));
const Workflow = React.lazy(() => import('../../components/Workflow'));
const Quote = React.lazy(() => import('../../containers/Quote'));
const PaymentConfirmation = React.lazy(() => import('../../containers/PaymentConfirmation'));
const FourOhFour = React.lazy(() => import('../../containers/FourOhFour'));
const Redirect = React.lazy(() => import('../../containers/Redirect'));
const PrivacyPolicy = React.lazy(() => import('../../containers/PrivacyPolicy'));
const TermsOfService = React.lazy(() => import('../../containers/TermsOfService'));
const AboutUs = React.lazy(() => import('../../containers/AboutUs'));
const ContactUs = React.lazy(() => import('../../containers/ContactUs'));
const Claims = React.lazy(() => import('../../containers/Claims'));
const Auto = React.lazy(() => import('../../containers/Product/Auto'));
const Bundle = React.lazy(() => import('../../containers/Product/Bundle'));
const Commercial = React.lazy(() => import('../../containers/Product/Commercial'));
const Condo = React.lazy(() => import('../../containers/Product/Condo'));
const HomeProduct = React.lazy(() => import('../../containers/Product/Home'));
const Landlord = React.lazy(() => import('../../containers/Product/Landlord'));
const Motorcycle = React.lazy(() => import('../../containers/Product/Motorcycle'));
const Pleasurecraft = React.lazy(() => import('../../containers/Product/Pleasurecraft'));
const Roommate = React.lazy(() => import('../../containers/Product/Roommate'));
const RV = React.lazy(() => import('../../containers/Product/RV'));
const Tenant = React.lazy(() => import('../../containers/Product/Tenant'));
const Login = React.lazy(() => import('../../containers/Login'));

class Routes extends React.Component {
    render() {

        return (
            <BrowserRouter>
                <Container {...this.props}>
                    <React.Suspense fallback={<div />}>
                        <Switch>
                            <Route exact path='/' render={(props) => <Home {...props} {...this.props} />} />
                            <Route exact path='/login' render={(props) => <Login {...props} {...this.props} />} />
                            <Route exact path='/workflow' render={(props) => <Workflow {...props} {...this.props} />} />
                            <Route exact path='/quote' render={(props) => <Quote {...props} {...this.props} />} />
                            <Route exact path='/paymentConfirmation' render={(props) => <PaymentConfirmation {...props} {...this.props} />} />

                            <Route exact path='/tenant' render={(props) => <Tenant {...props} {...this.props} />} />
                            <Route exact path='/home' render={(props) => <HomeProduct {...props} {...this.props} />} />
                            <Route exact path='/auto' render={(props) => <Auto {...props} {...this.props} />} />
                            <Route exact path='/bundle' render={(props) => <Bundle {...props} {...this.props} />} />
                            <Route exact path='/roommate' render={(props) => <Roommate {...props} {...this.props} />} />
                            <Route exact path='/landlord' render={(props) => <Landlord {...props} {...this.props} />} />
                            <Route exact path='/condo' render={(props) => <Condo {...props} {...this.props} />} />
                            <Route exact path='/commercial' render={(props) => <Commercial {...props} {...this.props} />} />
                            <Route exact path='/motorcycle' render={(props) => <Motorcycle {...props} {...this.props} />} />
                            <Route exact path='/rv' render={(props) => <RV {...props} {...this.props} />} />
                            <Route exact path='/pleasurecraft' render={(props) => <Pleasurecraft {...props} {...this.props} />} />
                            <Route exact path='/claims' render={(props) => <Claims {...props} {...this.props} />} />
                            <Route exact path='/privacy' render={(props) => <PrivacyPolicy {...props} {...this.props} />} />
                            <Route exact path='/terms' render={(props) => <TermsOfService {...props} {...this.props} />} />
                            <Route exact path='/about-us' render={(props) => <AboutUs {...props} {...this.props} />} />
                            <Route exact path='/contact-us' render={(props) => <ContactUs {...props} {...this.props} />} />
                            <Route exact path='/redirect' render={(props) => <Redirect {...props} {...this.props} />} />
                            <Route exact path='/insurance/' component={() => {
                                window.location.href = 'https://blog.insurego.ca/insurance/';
                                return null;
                            }} />
                            <Route exact path='/error' render={(props) => <FourOhFour type={`api_fail`} {...props} {...this.props} />} />
                        </Switch>
                    </React.Suspense>
                </Container>
            </BrowserRouter>
        );
    }
}

export default Routes;
