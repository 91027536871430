import "core-js/stable";
import "regenerator-runtime/runtime";

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { unregisterServiceWorker } from './registerServiceWorker';

import {
    ContextProvider,
    ReferenceProvider
} from 'metrix-common';

import App from './containers/App';
import StoreFrontProvider from './provider/storefront';

import enMessages from './messages/en.json';
import frMessages from './messages/fr.json';

const initialState = window.initialReduxState;

const store = configureStore(initialState);

const rootElement = document.getElementById('app');

ReactDOM.render(
    <Provider store={store}>
        <ContextProvider messages={{ en: enMessages, fr: frMessages }} overrideCulture={`en-CA`}>
            <StoreFrontProvider>
                <ReferenceProvider>
                    <App />
                </ReferenceProvider>
            </StoreFrontProvider>
        </ContextProvider>
    </Provider>,
    rootElement);

unregisterServiceWorker();

if (module.hot) {
    module.hot.accept();
}
