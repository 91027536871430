import React from 'react';
import styled from 'styled-components';

import { Container, Colors } from 'metrix-common';

import Routes from '../../components/Routes';
import SupportPartner from '../../components/SupportPartner';
import InsuregoHelmet from '../../components/Helmet';

const Support = styled(SupportPartner)`
    position: fixed;
    cursor: pointer;
    bottom: 40px;
    right: 10px;
`;

class App extends React.Component {
    render() {
        if (!window.matchMedia(`(min-width: 576px)`).matches) {
            let viewport = document.getElementsByTagName(`META`).viewport;
            if (!/maximum-scale/.test(viewport.content)) {
                viewport.content += `, maximum-scale=1`;
            }
        }
        window.zESettings = {
            webWidget: {
                color: {
                    theme: `${Colors.Primary}`
                }
            }
        };

        if (window.location.pathname === '/workflow') {
            window.zESettings = {
                webWidget: {
                    offset: {
                        vertical: '50px',
                        mobile: {
                            vertical: '85px'
                        }
                    }
                }
            }
        }

        if (window.location.pathname === '/quote') {
            window.zESettings = {
                webWidget: {
                    offset: {
                        vertical: '80px',
                        mobile: {
                            vertical: '85px'
                        }
                    }
                }
            }
        }

        return (
            <Container>
                <InsuregoHelmet {...this.props} />
                <Routes {...this.props} />
                <Support {...this.props} />
            </Container>
        );
    }
}

export default App;