export const GET_POLICY = "GET_POLICY";
export const GET_POLICY_SUMMARY = "GET_POLICY_SUMMARY";
export const EXECUTE_POLICY_ACTION = 'EXECUTE_POLICY_ACTION';
export const REDUCE_ENTITY = 'REDUCE_ENTITY';
export const REDUCE_POLICY = 'REDUCE_POLICY';
export const REDUCE_POLICY_SUMMARY = 'REDUCE_POLICY_SUMMARY';
export const REDUCE_POLICY_ELEMENT = 'REDUCE_POLICY_ELEMENT';
export const REDUCE_SUBMISSION_ELEMENT = 'REDUCE_SUBMISSION_ELEMENT';
export const REDUCE_PAYMENT_ELEMENT = 'REDUCE_PAYMENT_ELEMENT';
export const REDUCE_INSURED_ELEMENT = 'REDUCE_INSURED_ELEMENT';
export const REDUCE_PARAMETER = 'REDUCE_PARAMETER';
export const REDUCE_QUOTING = 'REDUCE_QUOTING';