import React from 'react';
import { Helmet } from 'react-helmet';

class InsuregoHelmet extends React.Component {
    getTags = (path) => {
        let title = ``;
        let description = ``;
        let keywords = ``
        let image = ``

        switch (path) {
            case `/home`:
                title = `Home Insurance | ${process.env.REACT_APP_Brand} | Get a Quote`;
                description = `Home insurance is a form of property insurance and could cover losses and damages to your home in the event of fire, flood, weather or theft.`;
                keywords = `home insurance, house insurance, online quote, insurance quote`;
                image = `${this.props.context.configuration.content}/images/home.jpg`;
                break;
            case `/condo`:
                title = `Condo Insurance | ${process.env.REACT_APP_Brand} | Get a Quote`;
                description = `Condominium insurance protects you and your personal belongings in the event of loss or damage from fire, flood or theft.`;
                keywords = `condo insurance, online quote, insurance quote, condominium insurance`;
                image = `${this.props.context.configuration.content}/images/condo.jpg`;
                break;
            case `/tenant`:
                title = `Tenant Insurance | ${process.env.REACT_APP_Brand} | Buy Online`;
                description = `If you rent, tenant insurance protects your personal property with contents insurance, personal liability coverage and additional living expenses.`;
                keywords = `tenant insurance, online quote, insurance quote, renters insurance`;
                image = `${this.props.context.configuration.content}/images/tenant.jpg`;
                break;
            case `/roommate`:
                title = `Roommates Insurance | ${process.env.REACT_APP_Brand} | Instant Approval`;
                description = `If you live with one or more people ${process.env.REACT_APP_Brand} now offers renters insurance for roommates. If you share your space, you should share your insurance!`;
                keywords = `roommate insurance, insurance for roommates, shared insurance`;
                image = `${this.props.context.configuration.content}/images/roommate.jpg`;
                break;
            case `/landlord`:
                title = `Landlord Insurance | ${process.env.REACT_APP_Brand} | Get a Quote`;
                description = `Insure their rental property from fire, wind or water from overflowing toilets, sinks, tubs, water heaters or leaky pipes with Landlord Insurance.`;
                keywords = `landlord insurance, investment insurance, online quote, insurance quote`;
                image = `${this.props.context.configuration.content}/images/landlord.jpg`;
                break;
            case `/auto`:
                title = `Auto Insurance | ${process.env.REACT_APP_Brand} | Get a Quote`;
                description = `Auto insurance protects against financial loss due to an accident or theft. Basic automotive insurance is a requirement for operating your vehicle.`;
                keywords = `auto insurance, auto insurance, online quote, insurance quote`;
                image = `${this.props.context.configuration.content}/images/auto.jpg`;
                break;
            case `/bundle`:
                title = `Home & Auto Insurance | ${process.env.REACT_APP_Brand} | Save More by Bundling`;
                description = `Save big on your property and automotive insurance by bundling. Get the best price and amazing coverage from ${process.env.REACT_APP_Brand}!`;
                keywords = `home and auto insurance, property insurance, insurance bundles`;
                image = `${this.props.context.configuration.content}/images/bundle.jpg`;
                break;
            case `/commercial`:
                title = `Commercial Insurance | ${process.env.REACT_APP_Brand} | Get a Quote`;
                description = `Thinking about Business Insurance? Need Professional Liability Insurance, Commercial Vehicle Insurance or General Liability Insurance? Think ${process.env.REACT_APP_Brand}.`;
                keywords = `commercial insurance, online quote, insurance quote`;
                image = `${this.props.context.configuration.content}/images/commercial.jpg`;
                break;
            case `/about-us`:
                title = `About Us | ${process.env.REACT_APP_Brand} | People Stuff`;
                description = `${process.env.REACT_APP_Brand} is a digital insurance brokerage, serving Ontario's automotive, home, condo, tenant, landlord and commercial insurance markets.`;
                keywords = `about us, ${process.env.REACT_APP_Brand}`;
                image = `${this.props.context.configuration.content}/images/about-us.jpg`;
                break;
            case `/contact-us`:
                title = `Contact Us | ${process.env.REACT_APP_Brand} | Friendly Stuff`;
                description = `Want to speak to a live insurance broker? Need to make a claim? Contact the insurance experts at ${process.env.REACT_APP_Brand} today!`;
                keywords = `contact us, ${process.env.REACT_APP_Brand}`;
                image = `${this.props.context.configuration.content}/images/contact-us.jpg`;
                break;
            case `/blog`:
                title = `${process.env.REACT_APP_Brand} Blog | Insurance in Minutes | The Bird is the Word`;
                description = `The Bird is the Word is ${process.env.REACT_APP_Brand}'s take on insurance and your source for informative, entertaining and educational insurance-related articles.`;
                image = `${this.props.context.configuration.content}/images/about-us.jpg`;
                break;
            case `/claims`:
                title = `Insurance Claims | ${process.env.REACT_APP_Brand} | Expert Advice`;
                description = `Our Insurance Experts help clients navigate their claims in a timely and professional manner.`;
                image = `${this.props.context.configuration.content}/images/claims.jpg`;
                break;
            case `/terms`:
                title = `Terms of Service | ${process.env.REACT_APP_Brand} | Wordy Stuff`;
                description = `This page contains ${process.env.REACT_APP_Brand}'s Terms of Service and discloses our commission rates as required by RIBO.`;
                image = `${this.props.context.configuration.content}/images/terms.jpg`;
                break;
            case `/privacy`:
                title = `Privacy Policy | ${process.env.REACT_APP_Brand} | Legal Stuff`;
                description = `Learn how ${process.env.REACT_APP_Brand} uses data to customize your experience navigating our website.`;
                image = `${this.props.context.configuration.content}/images/privacy.jpg`;
                break;
            default:
                title = `${process.env.REACT_APP_Brand}`;
                description = `${process.env.REACT_APP_Description}`;
                keywords = `insurance broker, on-line quote, insurance quote, insurance`;
                image = `${this.props.context.configuration.content}/images/homepage.jpg`;
                break;
        }

        return (
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={`${this.props.context.configuration.corporateWeb}${path}`} />
                <meta property="og:image" content={image} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@insurego1" />
                <meta name="twitter:creator" content="@insurego1" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
                <link href="https://calendly.com/assets/external/widget.css" rel="stylesheet" />
                <script src="https://calendly.com/assets/external/widget.js" type="text/javascript" />
                {this.props.context &&
                    this.props.context.configuration &&
                    this.props.context.configuration.gtm && <script async src={`https://www.googletagmanager.com/gtm.js?id=${this.props.context.configuration.gtm}`} />
                }
                {this.props.context &&
                    this.props.context.configuration &&
                    this.props.context.configuration.hotjar &&
                    <script async type={`text/javascript`}>
                    {`
                            (function(h,o,t,j,a,r){
                                h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
                                h._hjSettings={hjid:${this.props.context.configuration.hotjar},hjsv:6};
                                a=o.getElementsByTagName('head')[0];
                                r=o.createElement('script');r.async=1;
                                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                a.appendChild(r);
                            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                    `}
                    </script>
                }
            </Helmet>
        );
    }

    render() {
        return this.getTags(window.location.pathname);
    }
}

export default InsuregoHelmet;