import dotProp from 'dot-prop-immutable';

import {
    REDUCE_STOREFRONT_CONFIGURATION
} from '../../constants/actions/storefront';

const initialState = {
    storefront: null
};

function storefrontReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_STOREFRONT_CONFIGURATION:
            return dotProp.set(state, 'storefront', action.payload);
        default:
            return state;
    }
}

export default storefrontReducer;
